.section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: var(--grid-column-gap);
  overflow: hidden;
  height: 100%;
}

.sectionWithHeader {
  grid-row-gap: var(--content-margin-primary);
}

.sectionWithSecondRow {
  grid-template-rows: min-content 100vh;
}

.headerFirstColumn {
  grid-row-start: 1;
  grid-column-start: 1;
}

.headerSecondColumn {
  grid-row-start: 1;
  grid-column-start: 2;
}

.contentFirstColumn {
  grid-row-start: 2;
  grid-column-start: 1;
}

.contentSecondColumn {
  grid-row-start: 2;
  grid-column-start: 2;
}

.contentFirstColumn,
.contentSecondColumn {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.columnBorderRadius {
  border-radius: var(--outer-border-radius);
}

@media only screen and (max-width: 767px) {
  .section {
    display: flex;
    flex-direction: column;
    grid-row-gap: var(--page-padding);
  }

  .sectionWithHeader {
    grid-row-gap: var(--page-padding);
  }

  .contentFirstColumn {
    order: 4;
  }

  .contentSecondColumn {
    order: 3;
  }

  .contentFirstColumn,
  .contentSecondColumn {
    align-items: baseline;
  }

  .headerFirstColumn {
    order: 1;
  }

  .headerSecondColumn {
    order: 2;
  }

  .headerSecondColumn:not(:only-child) {
    margin-bottom: var(--page-padding);
  }

  .contentFirstColumn,
  .contentSecondColumn,
  .headerFirstColumn,
  .headerSecondColumn {
    grid-column-start: 1;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .rightSideText > p {
    grid-column-end: span 5;
  }

  .contentFirstColumn,
  .contentSecondColumn {
    align-items: flex-start;
  }

  .sectionWithSecondRow {
    grid-template-rows: min-content max-content;
  }

  .contentFirstColumn,
  .contentSecondColumn {
    max-height: 100vh;
  }
}
