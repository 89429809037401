.header {
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-column-gap: var(--grid-column-gap);
  padding: var(--page-padding);
  padding-bottom: 36rem;
  background-color: var(--bg-primary);
  align-items: flex-start;
}

.title {
  grid-column-end: span 6;
}

.experience {
  grid-column-end: span 2;
}

.contact {
  grid-column-end: span 3;
}

.about {
  grid-column-start: 12;
  text-align: end;
  background: none;
  color: #fff;
  border: none;
  cursor: pointer;
  font-family: inherit;
  padding: 0;
}

.experience > p:not(:first-child),
.contact > p:not(:first-child) {
  margin: 2rem 0;
}

.experience > p:last-child,
.contact > p:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .experience,
  .contact {
    display: none;
  }

  .title {
    grid-column-end: span 5;
  }

  .about {
    display: inline-block;
    grid-column-start: 6;
  }
}
