.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
}

.image,
.video {
  border-radius: var(--inner-border-radius);
  width: 100%;
}

.centered {
  height: calc(100% - 16.8rem);
  width: auto;
}

@media only screen and (max-width: 767px) {
  .assetContainerCentered {
    padding: 7rem;
  }

  .centered {
    height: auto;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .container {
    align-items: flex-start;
  }

  .assetContainerCentered {
    padding: 4rem;
  }

  .centered {
    height: auto;
    width: 100%;
  }

  .image:not(.centered) {
    height: 100vh;
    width: auto;
  }
}
