.text {
  font-family: var(--font-family-secondary);
  line-height: var(--font-secondary-line-height);
  font-size: 3.2rem;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .text {
    font-size: 2rem;
  }
}
