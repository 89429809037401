:root {
  --bg-primary: #000;
  --bg-secondary: #f3efe6;
  --bg-black-secondary: #0f1011;
  --font-primary: #fff;
  --font-secondary: #000;
  --grid-columns: repeat(12, 1fr);
  --grid-column-gap: 2rem;
  --page-padding: 2rem;
  --content-margin-primary: 6.4rem;
  --font-family-primary: "Helvetica Neue", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  --font-family-secondary: "EB Garamond";
  --font-secondary-line-height: 1.05;
  --inner-border-radius: 3rem;
  --outer-border-radius: 1rem;
}

html {
  font-size: 62.5%; /* changes a default 16px font size to 10px */
  background-color: var(--bg-primary);
}

body {
  margin: 0;
  font-size: 1.4rem;
  font-family: var(--font-family-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  font-size: 1.4rem;
}

@media only screen and (max-width: 767px) {
  :root {
    --page-padding: 1rem;
    --grid-columns: repeat(6, 1fr);
    --grid-column-gap: 1rem;
    --content-margin-primary: 2rem;
  }
}
