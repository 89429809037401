.img {
  width: 100%;
  object-fit: cover;
}

.twoColumnsSectionHeader {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: var(--grid-column-gap);
}

.twoColumnsSectionHeader > div {
  grid-column-end: span 3;
}

.twoColumnsSectionHeader > div > p:not(:first-child) {
  margin-top: 2rem;
  grid-column-end: span 3;
}

.fullColumnsGrid {
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-column-gap: var(--grid-column-gap);
}

.fullGridImage {
  margin-top: var(--content-margin-primary);
  grid-column-start: 1;
  grid-column-end: span 12;
  grid-row-start: 2;
}

.fullColumnsGrid > .rightSideText {
  grid-column-start: 7;
  grid-column-end: span 6;
}

.bottomTextSpecial {
  margin-top: 2rem;
}

.rightSideText {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: var(--grid-column-gap);
  align-self: baseline;
}

.rightSideText > p {
  grid-column-end: span 3;
}

.centralizedAsset {
  position: relative;
  background-color: #d9d185;
  height: 100%;
  width: 100%;
}

.centralizedAsset > div {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 30%; /* Center horizontally */
  transform: translate(-21%, -50%);
}

.customSection > :first-child,
.customAssetsSection > :first-child {
  margin-bottom: 2rem;
}

.customAssetHeaderTextSection > :first-child {
  margin-bottom: var(--content-margin-primary);
}

@media only screen and (max-width: 767px) {
  .rightSideText > p {
    grid-column-end: span 6;
  }

  .assetSectionWithMargin {
    margin-top: var(--page-padding);
  }

  .customAssetHeaderTextSection {
    margin-bottom: 0;
  }

  .customAssetsSection > :first-child {
    margin-bottom: var(--page-padding);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .rightSideText > p {
    grid-column-end: span 5;
  }

  .img {
    height: 100vh;
  }
}
