.container {
  position: relative;
}

.bleed {
  padding: 0;
  margin-left: -2rem;
  width: calc(100vw + calc(2 * var(--page-padding)));
}

@media only screen and (max-width: 767px) {
  .container {
    min-height: auto;
  }

  .bleed {
    width: auto;
    margin: 0 calc(-2 * var(--page-padding));
  }
}
