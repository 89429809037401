.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: var(--outer-border-radius);
  overflow: hidden;
}

.imagesContainer {
  padding: 13.4rem;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.bleed {
  padding: 0;
}

.imagesContainer > * {
  max-width: 100%;
}

.videoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.videoContainer > video,
.videoContainer > img {
  border-radius: var(--inner-border-radius);
  width: 100%;
}

.imagesContainer > div:not(:first-child) {
  margin-left: 16rem;
}

@media only screen and (max-width: 767px) {
  .imagesContainer {
    padding: 0;
  }

  .imagesContainer > :not(:first-child) {
    display: none;
  }

  .videoContainer {
    padding: 7rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .imagesContainer {
    padding: 0;
  }

  .imagesContainer > :last-child {
    display: none;
  }

  .videoContainer {
    padding: 7rem;
  }

  .imagesContainer > div:not(:first-child) {
    margin-left: 0rem;
  }
}
