.container {
  background-color: var(--bg-secondary);
  color: var(--font-primary);
  overflow: hidden;
}

.cases > *:not(:last-child) {
  margin-bottom: 56rem;
}

.cases {
  border-bottom-left-radius: 3rem;
  border-bottom-right-radius: 3rem;
  padding-bottom: 36rem;
  background-color: var(--bg-primary);
}

@media screen and (max-width: 767px) {
  .cases > *:not(:last-child) {
    margin-bottom: 30rem;
  }
}
