.container {
  display: flex;
  flex-direction: column;
  background-color: var(--bg-secondary);
  color: var(--font-secondary);
  padding: var(--page-padding);
  padding-top: 13rem;
  z-index: 1;
  position: relative;
}

.footer {
  margin-top: 53.6rem;
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-column-gap: var(--grid-column-gap);
}

.email {
  grid-column-start: 7;
}

.instagram {
  grid-column-start: 9;
}

.back {
  grid-column-start: 12;
  text-align: right;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  color: #000;
}

.back:visited,
.back:active {
  color: #000;
}

.content {
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-column-gap: var(--grid-column-gap);
}

.text {
  grid-column-start: 7;
  grid-column-end: span 6;
  font-family: var(--font-family-secondary);
  line-height: var(--font-secondary-line-height);
  font-size: 3.2rem;
}

.getInTouchBtn {
  display: none;
}

.mWhitespace {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .container {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .text {
    font-size: 2rem;
    margin-top: 4rem;
  }

  .email {
    display: none;
  }

  .instagram {
    display: none;
  }

  .date {
    grid-column-end: span 3;
  }

  .getInTouchBtn {
    display: block;
    padding: 1.3rem 0;
    font-family: var(--font-family-secondary);
    line-height: var(--font-secondary-line-height);
    font-size: 2rem;
    background-color: var(--bg-primary);
    color: #fff;
    border-radius: 5rem;
    margin-top: 40rem;
    border: none;
    text-align: center;
  }

  .footer {
    margin-top: 2rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .back {
    grid-column-start: 11;
    grid-column-end: span 2;
  }
}
