.container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: var(--grid-column-gap);
}

.container > * {
  grid-column-start: 1;
  grid-column-end: span 3;
}

.container > *:not(:first-child) {
  margin-top: 2rem;
}

@media only screen and (max-width: 767px) {
  .container > * {
    grid-column-start: 1;
    grid-column-end: span 6;
  }

  .container > *:not(:first-child) {
    margin-top: var(--content-margin-primary);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .container > * {
    grid-column-start: 1;
    grid-column-end: span 5;
  }
}
