.container {
  padding: var(--page-padding);
  z-index: 2;
  position: relative;
  background-color: var(--bg-primary);
}

.header {
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-column-gap: var(--grid-column-gap);
  margin-bottom: 2rem;
}

.title {
  grid-column-end: span 6;
}

.type {
  grid-column-end: span 2;
}

.category {
  grid-column-end: span 3;
}

.date {
  text-align: end;
}

.sections > :not(:first-child) {
  margin-top: 36rem;
}

.sections > :last-child {
  margin-top: 24rem;
}

@media only screen and (max-width: 767px) {
  .title {
    grid-column-end: span 4;
    grid-row-start: 1;
  }

  .category {
    grid-column-end: span 2;
    grid-row-start: 1;
  }

  .type {
    grid-column-end: span 4;
  }

  .date {
    grid-column-end: span 2;
    grid-column-start: 5;
  }

  .date,
  .category {
    text-align: end;
  }

  .sections > :not(:first-child) {
    margin-top: 12rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .sections > :not(:first-child) {
    margin-top: 18rem;
  }

  .category {
    grid-column-end: span 2;
  }

  .date {
    grid-column-end: span 2;
  }
}
