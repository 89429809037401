.img {
  max-height: 100vh;
}

.imgBleed {
  max-height: none !important;
  width: 100%;
}

.innerImg {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%);
}

.innerImg > img {
  width: 100%;
}

.extraContent {
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-column-gap: var(--grid-column-gap);
}

.extraContent > .rightSideContent {
  grid-column-start: 7;
  grid-column-end: span 6;
}

.bottomContent {
  margin-top: var(--content-margin-primary);
}

.bottomContent > div {
  grid-column-end: span 12;
}

.topContent {
  margin-bottom: var(--content-margin-primary);
}

.assetContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-height: 100vh;
  padding: 13.4rem 0;
}

.centered {
  height: 100%;
  width: auto;
  border-radius: var(--inner-border-radius);
}

.container {
  min-height: 100vh;
}

@media only screen and (max-width: 767px) {
  .centered {
    height: auto;
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .container {
    min-height: auto;
  }

  img.imgBleed {
    height: 100vh;
  }

  .imgBleed {
    width: 100%;
    object-fit: cover;
  }

  .innerImg {
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    margin: 0 2rem;
  }

  .extraContent > .rightSideContent {
    grid-column-start: 1;
    grid-column-end: span 6;
  }

  .assetContainer {
    padding: 7rem;
  }

  .maximizeMobile {
    padding: 0;
    width: 100vw;
    margin-left: -1rem;
  }

  .maximizeMobile > img {
    border-radius: 0;
  }

  .horizontalAsset {
    padding: 1rem !important;
  }

  .horizontalAsset > img {
    width: 100%;
    max-width: calc(100vw - 2rem);
    border-radius: 1.5rem !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .innerImg {
    transform: translate(-2rem, -50%);
    margin: 0 5rem;
  }

  .maximizeMobile {
    padding: 0;
    width: 100vw;
    margin-left: -2rem;
  }

  .maximizeMobile > img {
    border-radius: 0;
    width: 100%;
    height: auto;
  }

  .horizontalAsset {
    padding: 2rem !important;
    justify-content: flex-start;
  }

  .horizontalAsset > img {
    max-width: calc(100vw - 4rem);
    border-radius: 2rem !important;
  }
}
